@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~leaflet/dist/leaflet.css';
@import '~leaflet.fullscreen/Control.FullScreen.css';

ion-icon:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}

ion-toolbar {
  color: white !important;
}

ion-modal {
  &::part(content) {
    width: 80%;
    height: 80%;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
  }
}

ion-icon:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}

ion-toast {
  touch-action: none;
}

ion-spinner {
  color: var(--ion-color-secondary);
}

ion-checkbox {
  color: var(--ion-color-secondary) !important;
}

* {
  @media (any-hover: hover) {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
}

ion-content {
  --keyboard-offset: 0 !important;
}

ion-input,
ion-textarea,
input,
textarea {
  -webkit-transform: none !important;
  transform: none !important;
  pointer-events: all !important;
  @media (any-hover: hover) {
    -webkit-touch-callout: initial;
    -webkit-user-select: text;
    user-select: text;
  }
}

input {
  -webkit-transform: none !important;
  transform: none !important;
  outline: none;
}

.cloned-input {
  display: none !important;
}

// disable all spin buttons on number inputs
input[type='number'] {
  appearance: textfield !important;
}

input[type='number']:focus,
input[type='number']:hover {
  appearance: auto;
}

cdk-virtual-scroll-viewport {
  height: 100%;
  max-width: 100%;

  .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
  }

  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

.sis-close-button {
  font-size: 30px;
  color: white;
  cursor: pointer;
  @media screen and (max-width: 992px) {
    font-size: 35px;
  }
}

.sis-global-button-padding-right {
  padding-right: 10px;
  @media screen and (max-width: 700px) {
    padding-right: unset;
    margin-bottom: 5px;
  }
}

.sis-global-button-padding-left {
  padding-left: 10px;
  @media screen and (max-width: 700px) {
    padding-left: unset;
    margin-bottom: 5px;
  }
}

.sis-global-toast {
  opacity: 0.9;
  color: black;
  font-size: large;
  --border-radius: 6px;
}

.sis-global-row-30 > * {
  height: 30px;
  padding: 0;
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

.sis-global-row-40 > * {
  height: 40px;
  padding: 0;
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

.sis-global-row-50 > * {
  height: 50px !important;
  padding: 0;
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

.sis-global-row-60 > * {
  height: 60px;
  padding: 0;
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

.sis-global-row-70 > * {
  height: 70px;
  padding: 0;
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

.sis-global-row > * {
  padding: 0;
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

.sis-global-border-bottom-primary {
  border-bottom: 1px solid var(--ion-color-primary);
}

.sis-global-border-bottom-grey {
  border-bottom: 1px solid rgb(128, 128, 128, 0.3);
}

.sis-global-center {
  justify-content: center;
  text-align: center;
}

.sis-global-right {
  justify-content: right;
  justify-content: flex-end;
  text-align: right;
}

.sis-global-title {
  text-align: center;
  margin: 0px;
  font-size: 25pt;
  padding-top: 20px;
  padding-bottom: 20px;
}

.sis-global-noscrollbar {
  --offset-bottom: auto !important;
  --overflow: hidden;
  overflow: auto;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

.sis-global-bottom-border {
  border-bottom: 1px solid var(--ion-color-primary);
}

.sis-pdf-export {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  display: flex !important;
  align-content: center !important;
  align-items: center !important;

  z-index: 200;
}

.sis-ion-alert {
  --min-width: 400px;
  --width: 400px;
}

.sis-global-sticky {
  position: sticky;
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: white;
}

.sis-global-sticky-bottom {
  height: 45px;
  position: sticky;
  opacity: 1;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 0;
  background-color: white;
  border-top: 1px solid #ccc;

  ion-row {
    display: flex !important;
    align-content: center !important;
    align-items: center !important;
  }
}

.sc-ion-select-popover-h ion-list.sc-ion-select-popover {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.sis-global-page-grid {
  width: 70%;
  min-width: 992px;
  overflow-y: auto;
  padding: 0px;
  height: 100%;
}

@media screen and (max-width: 992px) {
  .sis-global-page-grid {
    width: 100%;
    min-width: unset;
    margin: 0;
    padding: 0px 10px 0px 10px;
  }
}

.sis-action-sheet-header .action-sheet-title {
  color: black;
  font-weight: bold;
}

.action-sheet-icon {
  fill: #757575;
}

.sis-global-sis-scrollbar {
  overflow-y: scroll;
  scrollbar-color: var(--ion-color-secondary-light) var(--ion-color-primary-light);
  scrollbar-width: thin !important;
}

.sis-global-sis-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.sis-global-sis-scrollbar::-webkit-scrollbar-track {
  background: var(--ion-color-primary);
}

.sis-global-sis-scrollbar::-webkit-scrollbar-thumb {
  background: var(--ion-color-secondary);
}

.sis-global-no-scrollbar {
  overflow: scroll;
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

.sis-global-default-cursor {
  cursor: default;
}

.sis-global-pointer-cursor {
  cursor: pointer;
}

.sis-global-popover {
  @media screen and (max-width: 700px) {
    --max-width: calc(100% - 20px);
  }
}

.sis-global-tooltip {
  .mdc-tooltip__surface {
    white-space: pre-line;
    text-align: left;
    max-width: none;
  }
}

.sis-global-text-input,
.sis-global-text-input:focus {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  border: 1px solid var(--ion-color-primary);
  display: inline-block;
  cursor: text;
}

.sis-global-text-input:disabled {
  background: none;
  cursor: default;
  border: 1px solid transparent;
}

.sis-global-input {
  --font-size: 16px;
  --height: 38px;
  --padding-end: 0px !important;
  --padding-start: 0px !important;
  --padding-top: 0px !important;
  --padding-bottom: 0px !important;
  --border-radius: 0px !important;
  padding-right: 5px !important;
  padding-left: 8px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-radius: 0px !important;
  text-align: left;
  font-size: var(--font-size) !important;
  background-color: white;
  height: var(--height) !important;
  border: 1px solid var(--ion-color-primary);
  width: 100%;
}

.sis-global-textarea-no-scrollbar * {
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

.sis-global-textarea {
  height: 100% !important;
  margin: 0px;
}

.sis-global-input-error {
  border: 1px solid var(--ion-color-danger);
}

ion-searchbar {
  height: 38px;
  padding: 0px !important;
  border: 1px solid var(--ion-color-secondary);
  --box-shadow: 0 !important;
  .searchbar-input-container {
    .searchbar-input {
      height: 36px !important;
      padding: 5px 45px 5px 45px !important;
      text-align: left;
    }

    .searchbar-search-icon {
      height: 20px !important;
      left: 10px !important;
      top: 8px !important;
      color: var(--ion-color-secondary);
    }

    .searchbar-clear-button {
      right: 10px;
    }
  }
}

.sis-global-hover:hover {
  background-color: var(--sis-color-table-hover);
}

ion-toolbar {
  background: var(--ion-color-secondary);
}

.sis-global-round-btn {
  margin: 0;
  background: white;
  border: 1px solid var(--ion-color-secondary);
  outline: 1px solid white;
}

a {
  color: var(--ion-color-secondary);
}
