.sis-mobile-size-modal {
  --backdrop-opacity: 0.3 !important;
  &::part(content) {
    height: 80%;
    width: 393px;
    @media screen and (max-width: 700px) {
      height: 100%;
      width: 100%;
    }
  }
}

.sis-main-menu-selection-modal {
  &::part(content) {
    height: 100%;
    max-height: 300px;
    width: 95%;
    max-width: 300px;
  }
}
.sis-main-menu-destination-selection-modal {
  &::part(content) {
    height: 100%;
    max-width: 500px;
    @media screen and (min-width: 500px) {
      height: 80%;
      max-height: 650px;
    }
    @media screen and (max-height: 400px) {
      height: 100%;
    }
  }
}

.sis-webcam-modal {
  &::part(content) {
    height: 802px;
    width: 1024px;
    @media screen and (max-width: 500px) {
      height: 100%;
      width: 100%;
    }
  }
}

.sis-one-cam-modal {
  &::part(content) {
    height: 632px;
    width: 1024px;
    @media screen and (max-width: 500px) {
      height: 100%;
      width: 100%;
    }
  }
}

.sis-infotext-modal {
  &::part(content) {
    height: 780px;
    width: 1000px;
    @media screen and (max-height: 780px) {
      height: 90%;
    }
    @media screen and (max-width: 500px) {
      height: 100%;
      width: 100%;
    }
  }
}

.sis-screeneditor-modal {
  &::part(content) {
    width: 400px;
    height: 530px;
    @media screen and (max-width: 500px) {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-height: 530px) {
      height: 90%;
    }
  }
}

.sis-operatinghour-modal {
  &::part(content) {
    width: 600px;
    height: 270px;
    @media screen and (max-width: 500px) {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-height: 270px) {
      height: 90%;
    }
  }
}

.sis-journey-editor-modal {
  &::part(content) {
    height: 500px;
    width: 575px;
    @media screen and (max-width: 500px) {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-height: 700px) {
      height: 90%;
    }
  }
}

.sis-journey-many-stations-editor-modal {
  &::part(content) {
    height: 680px;
    width: 550px;
    @media screen and (max-width: 500px) {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-height: 680px) {
      height: 90%;
    }
  }
}

.sis-season-editor-modal {
  &::part(content) {
    height: 400px;
    width: 550px;
    @media screen and (max-width: 500px) {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-height: 400px) {
      height: 90%;
    }
  }
}

.sis-interval-day-editor-modal {
  &::part(content) {
    height: 475px;
    width: 550px;
    @media screen and (max-width: 500px) {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-height: 475px) {
      height: 90%;
    }
  }
}

.sis-ropeway-ecomode-settings-modal {
  &::part(content) {
    height: 600px;
    width: 700px;
    @media screen and (max-width: 500px) {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-height: 600px) {
      height: 90%;
    }
  }
}

.sis-ropeway-settings-modal {
  &::part(content) {
    height: 550px;
    width: 700px;
    @media screen and (max-width: 500px) {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-height: 550px) {
      height: 90%;
    }
  }
}

.sis-alarmlist-modal {
  z-index: 8 !important;
  @media screen and (max-width: 992px) {
    margin-top: 56px;
  }
  @media screen and (max-height: 700px) {
    height: 90%;
  }
}

.sis-image-browse-modal,
.sis-video-browse-modal {
  &::part(content) {
    height: 750px;
    width: 500px;
  }
  @media screen and (max-height: 750px) {
    height: 90%;
  }
}

.sis-modal-grid {
  padding: 20px;
  overflow-y: auto;

  @media screen and (max-width: 700px) {
    padding: 10px 10px 0px 10px;
  }
}

.sis-sismedia-operating-hours-modal {
  &::part(content) {
    width: 1100px;
    height: 510px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 700px) {
      height: 90%;
    }
  }
}

.sis-sismedia-edit-modal-lift {
  &::part(content) {
    width: 520px;
    height: 715px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 715px) {
      height: 90%;
    }
  }
}

.sis-sismedia-edit-modal-slope {
  &::part(content) {
    width: 500px;
    height: 610px;
    @media screen and (max-width: 800px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 610px) {
      height: 90%;
    }
  }
}

.sis-sismedia-edit-modal-trail {
  &::part(content) {
    width: 500px;
    height: 680px;
    @media screen and (max-width: 720px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 680px) {
      height: 90%;
    }
  }
}

.sis-sismedia-edit-modal-gastro {
  &::part(content) {
    width: 500px;
    height: 680px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 680px) {
      height: 90%;
    }
  }
}

.sis-sismedia-edit-modal-poi {
  &::part(content) {
    width: 500px;
    height: 540px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 540px) {
      height: 90%;
    }
  }
}

.sis-parking-edit-modal {
  &::part(content) {
    width: 700px;
    height: 480px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 480px) {
      height: 90%;
    }
  }
}

.sis-parking-exports-edit-modal {
  &::part(content) {
    width: 600px;
    height: 500px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 500px) {
      height: 90%;
    }
  }
}

.sis-parking-occupancy-edit-modal {
  &::part(content) {
    width: 290px;
    height: 330px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 350px) {
      height: 90%;
    }
  }
}
.sis-infobanner-modal {
  &::part(content) {
    width: 800px;
    height: 600px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 700px) {
      height: 90%;
    }
  }
}

.sis-infobanner-plaintext-modal {
  &::part(content) {
    width: 800px;
    height: 600px;
  }
  @media screen and (max-height: 600px) {
    height: 90%;
  }
}

.sis-sismedia-asset-override-modal {
  &::part(content) {
    width: 1000px;
    height: 500px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 500px) {
      height: 90%;
    }
  }
}

.sis-sismedia-exports-edit-modal {
  &::part(content) {
    width: 600px;
    height: 500px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 500px) {
      height: 90%;
    }
  }
}

.sis-user-management-edit-modal {
  &::part(content) {
    width: 600px;
    height: 520px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 520px) {
      height: 90%;
    }
  }
}

.sis-time-control-modal {
  &::part(content) {
    width: 600px;
    height: 690px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 690px) {
      height: 90%;
    }
  }
}

.sis-operatinginfo-edit-modal {
  &::part(content) {
    width: 800px;
    height: 610px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 610px) {
      height: 90%;
    }
  }
}

.sis-breakdown-library-modal {
  &::part(content) {
    width: 800px;
    height: 600px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 600px) {
      height: 90%;
    }
  }
}

.sis-breakdown-library-item-modal {
  &::part(content) {
    width: 800px;
    height: 705px;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 705px) {
      height: 90%;
    }
  }
}

.sis-infobanner-screen-selector-modal {
  &::part(content) {
    width: 800px;
    height: 600px;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
    @media screen and (max-height: 600px) {
      height: 90%;
    }
  }
}

.sis-weblink-edit-modal {
  &::part(content) {
    width: 800px;
    height: 500px;

    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 500px) {
      height: 90%;
    }
  }
}

.sis-ropeway-chart-modal {
  &::part(content) {
    width: 95%;
    height: 90%;

    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 700px) {
      width: 100%;
      height: 100%;
    }
  }
}

.sis-ropeway-ecomode-chart-modal {
  &::part(content) {
    width: 95%;
    height: 90%;

    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 700px) {
      width: 100%;
      height: 100%;
    }
  }
}

.sis-tenant-edit-modal {
  &::part(content) {
    width: 450px;
    height: 550px;

    @media screen and (max-width: 500px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 550px) {
      height: 90%;
    }
  }
}

.sis-infobanner-wysiwyg-modal {
  &::part(content) {
    width: 1542px;
    height: 800px;

    @media screen and (max-width: 1550px) {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-height: 800px) {
      height: 100%;
      width: 100%;
    }
  }
}

.sis-modal-footer {
  position: relative;
  bottom: 0px;
  div {
    padding: 20px;
    text-align: right;
    max-height: 76px;

    @media screen and (max-width: 700px) {
      padding: 10px;
      text-align: unset;
      max-height: unset;
    }
  }
}

.sis-modal-input {
  font-size: 14px;
  font-weight: 100;
  --padding-end: 0px !important;
  --padding-start: 0px !important;
  --padding-top: 0px !important;
  --padding-bottom: 0px !important;
  --border-radius: 0px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px;
  border-radius: 0px;
  border: 1px solid var(--ion-color-primary);
  text-align: left;
  background-color: white;
  width: 100%;
}

.sis-modal-text-input,
.sis-modal-text-input:focus {
  height: 30px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid var(--ion-color-primary);
  display: inline-block;
  cursor: text;
}

.sis-modal-text-input:disabled {
  background: none;
  cursor: default;
  border: 1px solid transparent;
}

.sis-modal-textarea-input {
  height: calc(100% - 5px);
}

.sis-modal-time-input {
  height: 30px;
  max-width: 50%;
}

.sis-modal-row-70 {
  height: 70px;

  display: flex;
  align-content: center;
  align-items: center;

  ion-col {
    height: 100%;
    padding: 0;
  }
}

.sis-modal-row-40 {
  height: 40px;
  padding: 0;

  display: flex;
  align-content: center;
  align-items: center;

  ion-col {
    padding: 0;
  }
}

.sis-modal-row-35 {
  height: 35px;
  padding: 0;

  ion-col {
    padding: 0;
  }
}

.sis-modal-input-label {
  font-size: 14px;
  padding: 6px 0 !important;
}

.sis-modal-input-error {
  border: 1px solid var(--ion-color-danger);
}
